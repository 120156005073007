@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


body, html, #root {
  background: #1c0124;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  margin: 0% !important;
}

  * {
    box-sizing: border-box;
  }

.borders{
  border: 2px solid white !important;
}
.bg-gradient-light{
  background: linear-gradient(90deg, #00FFFF 0%, #00F9A9 100%);
}
.kil{
  background: linear-gradient(90deg, #00FFFF 0%, #00F9A9 100%);
}
.jik{
  background: linear-gradient(90deg, #00FFFF 0%, #00F9A9 100%);
}
.container{
  background: #33254C;
}
.dfcv{
  background: #33254C;
}

.custom-border {
  @apply border-2 border-secondary border-opacity-30 rounded-2xl;
}

@layer utilities {

  ::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #00ffee81;
    border-radius: 5px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

/* react-day-picker style overrides */
.dialog-sheet .rdp {
  --rdp-cell-size: 30px; 
  --rdp-accent-color: #00FFEE88;
  --rdp-background-color: #00FFEE55;
}